
<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_pidtps',
      headers: [
        [{
          label: 'Jenis Publikasi',
          dataKey: 'jenis_publikasi',
          order: 1,
          attributes: { rowspan: 2 },
        }, {
          label: 'Jumlah Judul',
          attributes: { colspan: 3 },
        }, {
          label: 'Jumlah',
          dataKey: 'jumlah',
          order: 5,
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center' },
        }],
        [{
          label: 'TS-2',
          dataKey: 'ts_2',
          order: 2,
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'TS-1',
          dataKey: 'ts_1',
          order: 3,
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'TS',
          dataKey: 'ts',
          order: 4,
          dataAttributes: { class: 'text-center' },
        }]
      ],
      form: {
        inputs: [{
          label: 'Jenis Publikasi',
          name: 'jenis_publikasi',
          dataKey: 'jenis_publikasi',
          type: 'textarea',
          rules: 'required',
        }, {
          label: 'TS-2',
          name: 'ts_2',
          dataKey: 'ts_2',
          type: 'number',
          defaultValue: 0,
          col: 4
        }, {
          label: 'TS-1',
          name: 'ts_1',
          dataKey: 'ts_1',
          type: 'number',
          defaultValue: 0,
          col: 4
        }, {
          label: 'TS',
          name: 'ts',
          dataKey: 'ts',
          type: 'number',
          defaultValue: 0,
          col: 4
        }],
      },
    }
  },
}
</script>
